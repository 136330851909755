import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from './component/Navigation';
import MainHome from './Main';
import ServiceBox from './component/ServiceBox.jsx';
import FooterBox from './component/Footer';
import About from './component/About.jsx'
import ScrollToTop from './component/ScrollToTop.jsx';
import './index.css';

export default function App() {
  return (
    <>
      <ScrollToTop />
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={< MainHome />}></Route>
          <Route exact path='/about' element={< About />}></Route>
        </Routes>
      </Router>
      <ServiceBox />
      <FooterBox />
    </>
  )
}

