import React from 'react';
import CourseBox from './CourseBox.jsx';

export default function TutorialBox(props) {
    
    return (
        <>
            {props.tutorialList.map((data, index) => (
                <div className="card m-2" key={data.id}>
                    <div className="card-body h4">
                        <h5>{data.name}</h5>
                        <div className='row'>
                            <CourseBox tutorialId={data.id} />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}