import React from 'react'
import Logo_main from './images/youtube-logo.jpg';
import Banner from './banner'


export default function Home(props) {
    return (
        <>

            <div className="card mx-2 my-2">
                <div className="card-body h4">
                    <img src={Logo_main} className="col-2" id="logo-image-youtube"  alt=''/>
                    Visit Our YouTube Channel For Video Tutorials : 
                    <a href="https://www.youtube.com/results?search_query=pankaj+panjwani" target="_blank" rel="noreferrer">Join Now</a>
                </div>
            </div>

            <Banner banner={props.bannerList} />
        </>

    );
}