import './App.css';
import Home from './component/Home';
import TutorialBox from './component/TutorialBox';
import AuthorBox from './component/AuthorBox';
import LatestBox from './component/LatestBox.jsx';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Url } from './constant/global.js';

export default function App() {

    const [banner, setBanner] = useState([]);
    const [tutorial, setTutorial] = useState([]);
    const [author, setAuthor] = useState([]);

    const fetchBannerData = () => {
        axios.get(Url+'/tutorials/aBkxYl1h0q/banners/')
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let mybanner = data;
                setBanner(mybanner);
            })
    }

    const fetchTutorialData = () => {
        axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-types/')
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let mytutorial = data;
                setTutorial(mytutorial);
            })
    }

    const fetchAuthorData = () => {
        axios.get(Url+'/tutorials/aBkxYl1h0q/author-views/')
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let myauthor = data;
                setAuthor(myauthor);
            })
    }


    useEffect(() => {
        fetchBannerData();
        fetchTutorialData();
        fetchAuthorData();
    }, [])



    return (
        <>

            <div className='row'>
                <div className='col-md-7 col-xs-12 col-lg-7 col-xl-7 my-2'>
                    <Home bannerList={banner} />
                    <TutorialBox tutorialList={tutorial} />
                    <AuthorBox authorList={author} />

                </div>
                <div className='col-md-4 col-xs-12 col-lg-4 col-xl-4'>
                    <h4 className="latest-box mt-3 h4">Recent Developments</h4>
                    <LatestBox />
                </div>

            </div>


        </>
    )
}

