import React from 'react'
import Logo_main from './images/1.png';
import { useState, useEffect } from 'react'
import axios from 'axios';
// import * as FaIcons from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";
import * as FaIcons from 'react-icons/fa';
import { Url } from '../constant/global.js';

export default function Navigation(props) {


  const [company, setCompany] = useState([]);
  const [navdata, setNavdata] = useState([]);
  const [headingdata, setHeadingdata] = useState([]);
  const [detaildata, setDetaildata] = useState([]);

  const myarray = [];


  const location = useLocation();
  const baseUrl = (window.location).search; // You can also use document.URL
  const urlParams = new URLSearchParams(baseUrl);
  const param1 = urlParams.get('courseid');
  const param2 = urlParams.get('headingid');
  var isLoggedIn = false;
  const mylocation = window.location.pathname;

  if(mylocation == '/about/'){
    isLoggedIn = true;
  }else{
    isLoggedIn = false;
  }

  const fetchCompanyData = () => {
    axios.get(Url+'/tutorials/aBkxYl1h0q/companies/1/')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        let mycompany = data;
        setCompany(mycompany);
      })
  }

  const fetchNavData = () => {
    axios.get(Url+'/tutorials/aBkxYl1h0q/tutorials/')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        let mynavdata = data;
        setNavdata(mynavdata);

        for (var i = 0; i < mynavdata.length; i++) {
          axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-headings/?tutorial=' + mynavdata[i].id)
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              let mydata = data;
              if (mydata[0] !== undefined) {
                if (myarray.indexOf(mydata[0].id) == -1) {
                  myarray.push(mydata[0].id);
                  setHeadingdata(current => [...current, mydata[0].id]);

                  axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-details/?tutorialheading=' + mydata[0].id)
                    .then((response) => {
                      return response.data;
                    })
                    .then((data) => {
                      let udata = data;
                      if (udata == 'undefined'){
                        console.log("Bhuwan", udata[0]['id']);
                        setDetaildata(current => [...current, udata[0]['id']]);
                      }
                  })
                }
              }
            })
          }
      })
  }

  const shoot = () => {
    window.showsidebar();
  }

   let button;
   if (isLoggedIn) {
     button = <Link to='#crossid' className='menu-bars' id="toggleid">
                <FaIcons.FaBars onClick={shoot} />
              </Link>;
   } else {
     button = '';
   }
  


  useEffect(() => {
    fetchCompanyData();
    setTimeout(fetchNavData(), 7000);
  }, [])




  return (
    <>
      <div className="container-fluid bg-white">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid bg-white">
            <h1 className="navbar-brand font-reponsive bg-white">

              <img src={Logo_main} className="col-1" id="logo-image" alt="Tutorial-Logo" />
              {company.title}
            </h1>

            <div className="gcse-search"></div>
            
          </div>
        </nav>
      </div>

        <div className='scrollmenu'>
              {button}

              <Link className="nav-link active" aria-current="page" to="/"><i className='fa fa-home'></i></Link>
              {navdata.map((data, index) => (
                  <Link className="nav-link" to={"/about/?headingid=" +
                              data.slug
                            + "&courseid=" + data.id}>{data.coursetitle}
                  </Link>
              ))}
        </div>
    </>
  );
}
