import React from 'react';


export default function AuthorBox(props) {
    
    return (
        <>
            {props.authorList.map((data, index) => (
                <div className="card m-2" key={data.id}>
                    <div className="row g-0">
                        <div className="container">
                            <p>
                                <img src={data.image} className="img-fluid rounded-start author-image" alt={data.name}/>
                                <h4 className="my-3 h4">{data.title}</h4>
                                <span id="author-txt" dangerouslySetInnerHTML={{ __html: data.description }} />
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

