import React from 'react'

export default function banner(props) {
    return (
        <>
        <div className="card m-2">
            <div className="card-body">
                {props.banner.map(data => (
                    <a href={data.hreflink} key={data.id} target="_blank" rel="noreferrer">
                        <img src={data.thumbnail} className="img-fluid img-thumbnail col-md-5 col-xs-12" id="banner-image" alt=''/>
                    </a>
                ))
                }
            </div>
        </div>
        </>

    );
}