import React from 'react';
import Sidenav from './Sidenav';

export default function About() {
  return (
    <div>
        <Sidenav/>
    </div>
  )
}
