import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from '../constant/global.js';

export default function Footer(props) {
    const [footer, setFooter] = useState([]);

    const fetchFooterData = () => {
        axios.get(Url+'/tutorials/aBkxYl1h0q/footers/1/')
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            let mysfoot = data;
            setFooter(mysfoot);
          })
    }

    useEffect(() => {
        fetchFooterData();
    }, [])

    return (
        <>

            <div className="bg-success bg ml-2 p-3" align='center'>
                <div
                    dangerouslySetInnerHTML={{ __html: footer.footer }}/>
            </div>

        </>
    );
}

