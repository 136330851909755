import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Url } from '../constant/global.js';


export default function LatestBox(props) {
    // console.log(props)
    const [latest, setLatest] = useState([]);

    const fetchLatestData = () => {
        axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-details/')
        .then((response) => {
            return response.data;
        })
        .then((data) => {
          let myslatest = data;
          console.log(myslatest);
          setLatest(myslatest);
        })
      }

      const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

    useEffect(() => {
        fetchLatestData();
      }, [])

    return (
        <>

            <ol type="1" className='mt-4'>
                {latest.map((data, index) => (
                    <li key={data.id} className='m-2'>
                        <Link to={"/about/?headingid=" +
                              data.id + "&courseid="+data.slug} className="latest-text">
                            {new Date(data.created).getDate() + nth(new Date(data.created).getDate()) +" "+new Date(data.created).toLocaleString('default', { month: 'short' }) + " - "+ data.title}
                        
                        </Link>
                    </li>
                ))}
            </ol>
        </>
    );
}

