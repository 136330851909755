import React, { useState, useEffect } from 'react';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import InsideNavbar from './insideNavbar.jsx'
import './sidebar.css';
import axios from 'axios';
import { IconContext } from 'react-icons';
import { Url } from '../constant/global.js';

function Navbar() {

    // const location = useLocation();
    const [sidebar, setSidebar] = useState(false);
    const [sidedata, setSidebarData] = useState([]);

    const baseUrl = (window.location).search;
    const urlParams = new URLSearchParams(baseUrl);
    const param1 = urlParams.get('courseid');
    // const param2 = urlParams.get('headingid');

    const showSidebar = () => setSidebar(!sidebar);

    
    

    useEffect(() => {

        const fetchNavData = () => {
            axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-headings/?tutorial='+param1)
                .then((response) => {
                return response.data;
                })
                .then((data) => {
                let mydata = data;
                setSidebarData(mydata);
                })
            }
        
        const sideColor = () => {
            
        }


        fetchNavData();
        sideColor();
    }, [param1])

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className={sidebar ? 'nav-menu active d-none d-md-block d-lg-none d-none d-sm-block' : 'nav-menu'} id='navbar'>
                    
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars' id="crossid">
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        <br/>
                        {sidedata.map((item, index) => {
                            return (
                                <span key={index}>
                                   
                                    <AiIcons.AiTwotoneBulb/>
                                    <span className='nav-head'>{item.heading}</span>
                                    <div>
                                        <InsideNavbar insidedata={item.id}/>
                                    </div> 
                                </span>
                                
                                
                            );
                        })}
                </div>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;