import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios';
import * as AiIcons from 'react-icons/ai';
import { Link, useLocation } from "react-router-dom";
import { Url } from '../constant/global.js';

export default function InsideNavbar(props) {

    const location = useLocation();
    const [navdata, setNavdata] = useState([]);
    // const [headingdata, setHeadingdata] = useState([]);
    const baseUrl = (window.location).search; // You can also use document.URL
    const urlParams = new URLSearchParams(baseUrl)
    const param1 = urlParams.get('courseid');
    const fetchNavData = () => {
        var myid = props.insidedata;
        axios.get(Url+'/tutorials/aBkxYl1h0q/tutorial-detail-sides/?tutorialheading=' + myid)
        .then((response) => {
            return response.data;
        })
        .then((data) => {
            let mydata = data;
            setNavdata(mydata);
        })
    }

    useEffect(() => {
        fetchNavData();
    }, [props])

    return (
        <>
            {navdata.map(data => (
                <div className='nav-text' key={data.id}>
                    {window.location.search == "?headingid="+data.id+"&courseid="+ param1 ?
                    <Link to={"/about/?headingid="+data.id+"&courseid="+ param1} className='nav-active'>
                        <span><AiIcons.AiFillPushpin /></span>
                        <span>{data.title}</span>
                    </Link>
                    :
                    <Link to={"/about/?headingid="+data.id+"&courseid="+ param1}>
                        <span><AiIcons.AiFillPushpin /></span>
                        <span>{data.title}</span>
                    </Link>
                    
                    }
                    
                </div>
                
            ))}
        </>
    );
}
