import React from 'react';
import Sidebar from './Sidebar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { Url } from '../constant/global.js';
import {
    EmailShareButton,
    EmailIcon,
    InstapaperIcon,
    LinkedinIcon,
    WhatsappIcon,
    TwitterIcon,
    TelegramIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

export default function Sidenav(props) {
    const location = useLocation();
    const count = 0;
    const [detail, setDetail] = useState([]);
    const [myshare, setShare] = useState([]);
    const baseUrl = (window.location).search;
    const urlParams = new URLSearchParams(baseUrl);
    const param1 = urlParams.get('courseid');
    const param2 = urlParams.get('headingid');
    const fetchCourseData = () => {

        axios.get(Url + '/tutorials/aBkxYl1h0q/tutorial-detail-sides/?id=' + param2)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let mycourse = data;
                // console.log(mycourse)
                setDetail(mycourse);
            })
    }

    const fetchShareData = () => {

        axios.get(Url + '/tutorials/aBkxYl1h0q/shares/?tutorial=' + param2)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let mycourse = data;
                // console.log(mycourse)
                setShare(mycourse);
            })
    }

    useEffect(() => {
        fetchCourseData();
        fetchShareData();
    }, [param2])
    return (
        <>

            <div className="row">
                <div className='col-md-2 col-xs-10'>
                    <Sidebar />
                </div>
                <div className="col-md-7 col-xs-10 course-area card">
                    {detail.map(data => (
                        <div key={data.id} className='card-body p-0'>
                            <h3>{data.maintitle}</h3>
                            <div
                                dangerouslySetInnerHTML={{ __html: data.description }}
                            />
                        </div>
                    ))}

                    <div className='card-body bg-light m-0 mt-2 text-center'>
                        <p className='h5 pull-left'>Feedback : <span>feedback@panjwanitutorials.com</span></p>
                    </div>

                    {myshare.map(data => (
                        <div className='card-body bg-light m-0 mt-2 text-center'>
                            <iframe width="100%" height="400" className='p-0 m-0'
                                src={"https://www.youtube.com/embed/"+data.youtube}>
                            </iframe>
                        </div>
                    ))}

                    <div className='card-body bg-light mt-2'>
                        <button class="btn btn-default">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" fill="currentColor" class="bi bi-share" viewBox="">
                                <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                            </svg>
                            <b className='h3 mx-2 mt-5'>Share</b>
                        </button>
                        <div className='pull-right'>
                            <FacebookShareButton url={window.location.href} target="_blank">
                                <FacebookIcon className='' size={52} round={true} />
                            </FacebookShareButton>
                            <WhatsappShareButton url={window.location.href} target="_blank">
                                <WhatsappIcon className='ml-2' size={52} round={true} /></WhatsappShareButton>
                            <TelegramShareButton url={window.location.href}>
                                <TelegramIcon className='ml-2' size={52} round={true} /></TelegramShareButton>
                            
                            <LinkedinShareButton url={window.location.href}>
                                <LinkedinIcon className='ml-2' size={52} round={true} /></LinkedinShareButton>

                            <TwitterShareButton url={window.location.href}>
                                <TwitterIcon className='ml-2' size={52} round={true} /></TwitterShareButton>
                        </div>
                    </div>

                    

                    {myshare.map(data => (
                        
                        <div className='card-body bg-primary mt-2 text-center'>
                            <a href={"https://www.youtube.com/watch?v="+data.youtube} target="_blank">
                            <button className='btn btn-danger mx-2'>Watch on Youtube</button>
                            </a>
                            <a href={data.instamojo_link} target="_blank">
                                <button className='btn btn-warning text-dark ml-2'>Buy Rs. {data.price}</button>
                            </a>
                        </div>
                    ))}

                </div>
                <div className="col-md-3 col-xs-10">
                    {/* <h3>Panjwani Tutorial Services</h3> */}
                </div>
            </div>
        </>
    );
}

