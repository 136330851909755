import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Url } from '../constant/global.js';

export default function CourseBox(props) {
    const [course, setCourse] = useState([]);

    const fetchCourseData = () => {
        let myid = props.tutorialId;
        
        axios.get(Url+'/tutorials/aBkxYl1h0q/tutorials/?tutorialtype='+myid)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                let mycourse = data;
                setCourse(mycourse);
            })
    }


    useEffect(() => {
        fetchCourseData();
    }, [])
    return (
        <>
            {course.map(data => (
                
                    <div className="card col-md-2  col-4 col-sm-5 m-2" key={data.id}>
                        <Link className="nav-link" to={"/about/?headingid=" + data.slug + "&courseid=" + data.id}>
                        <div className="card-body" align='center'>
                            <i className={data.icon} id="logo-image-fa"></i>
                            <h6 align="center" className='course-txt'>{data.coursetitle}</h6>
                        </div>
                        </Link>
                    </div>
                
            ))}
        </>
    );
}