import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from '../constant/global.js';

export default function AuthorBox(props) {

  const [service, setService] = useState([]);

  const fetchServiceData = () => {
    axios.get(Url+'/tutorials/aBkxYl1h0q/services/')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        let myservice = data;
        setService(myservice);
      })
  }

  useEffect(() => {
    fetchServiceData();
  }, [])

  return (
    <>
      <div className="bg-dark bg ml-2 mt-4 p-5">
        <h3>Panjwani Tutorial Services</h3>
        <ol type="1" className='mt-4'>
          {service.map((data, index) => (
            <li key={data.id} className='m-2'>
              {data.title}
            </li>
          ))}
        </ol>
      </div>
    </>
  );
}

